import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

const contentpublic = ({ data }) => {
  const publicContent = data.btswebMenuJson
  // lets find all the images!
  const altTextRegex = new RegExp(/<img.*?alt="(.*?)".*>/gi)

  let match = ''
  let newHtml = JSON.parse(JSON.stringify(publicContent.Content))
  let lastIndexOf = 0

  // Loop thru all regex.exec() matches to find and replace all image blobs
  while ((match = altTextRegex.exec(newHtml)) !== null) {
    // Get the Alt Text values first to get the name of the picture
    const fileName = match[1].split(',')[1]

    // Search graphQL to find image based on above fileName
    const findPublicURL = data.allFile.edges.find(
      pic => pic.node.base === fileName
    )

    // Now find first image tag
    const regex = /<img.*?src="(.*?)"/

    // search only the html that is left from the last search
    let src = regex.exec(newHtml.substring(lastIndexOf))[1]

    newHtml = newHtml.replace(src, findPublicURL.node.publicURL)

    lastIndexOf =
      newHtml.indexOf(findPublicURL.node.publicURL) +
      findPublicURL.node.publicURL.length
  }

  // Find all Documents and replace href's of documents by finding the ContentID
  const fileDownloadRegex = new RegExp(/href=(["'])(.*?)\1/gi)

  let matchFile = ''
  let fileDownloadHtml = JSON.parse(JSON.stringify(newHtml))
  let numFilesChanged = 0
  let numHrefsFound = 0

  // Loop thru all regex.exec() matches to find and replace all href with the publicURls of the document
  while ((matchFile = fileDownloadRegex.exec(fileDownloadHtml)) !== null) {
    const hrefToReplace = matchFile[2]
    const regexNumbers = /\d+/

    // Find numbers in the href
    let docNum = regexNumbers.exec(matchFile[0])

    // show contentID var
    // console.log('find numbers', docNum)

    // If a string is found then continue
    if (docNum !== null) {
      // turn the string content id to an INT
      const docID = parseInt(docNum[0], 10)

      // find the document in the btswebcivcorps nodes by content ID
      const findDocument = data.allBtswebCivcorpsdocumentJson.edges.find(
        pic => pic.node.document_id === docID
      )

      // Show whether or not the document is found.
      // if (findDocument !== undefined) {
      //   console.log('found doc', findDocument.node.document_name, docID)
      // }
      // count an href found
      numHrefsFound = numHrefsFound + 1

      // if found then get the public url to put in place of the HREF
      if (findDocument !== undefined) {
        const docPublicUrl = data.allFile.edges.find(
          doc => doc.node.base === findDocument.node.document_name
        )
        // console.log('found file - ', docPublicUrl)
        if (docPublicUrl !== undefined) {
          // console.log('found file locally - ', docPublicUrl.node.base)
          // console.log('href to replace', hrefToReplace)
          // console.log('file local URL:', docPublicUrl.node.publicURL)

          // Replace the hrefs with the public URL of the document found
          fileDownloadHtml = fileDownloadHtml.replace(
            hrefToReplace,
            docPublicUrl.node.publicURL
          )
          numFilesChanged = numFilesChanged + 1
        } else {
          // console.log('docPublicURL not found', docPublicUrl, docID)
        }
      } else {
        // console.log('----- FILE NOT FOUND for ', docID)
      }
    }
  }
  // console.log('Number of files replaced:', numFilesChanged)
  // console.log('Number of hrefs found:', numHrefsFound)

  return (
    <Layout>
      <div
        className="usa-grid"
        style={{ minHeight: '400px', padding: '48px 0' }}
      >
        <div
          className="content usa-width-two-thirds usa-offset-one-twelfth"
          dangerouslySetInnerHTML={{ __html: fileDownloadHtml }}
        />
      </div>
    </Layout>
  )
}

export default contentpublic;

export const query = graphql`
  query contentpublic($id: Int) {
    btswebMenuJson(ID: { eq: $id }) {
      ID
      Title
      Link
      Parent
      Content_ID
      SortOrder
      Content
      ViewType
      ThumbNail
      CreateBy
      CreateDate
    }
    allFile {
      edges {
        node {
          base
          publicURL
        }
      }
    }
    allBtswebCivcorpsdocumentJson {
      edges {
        node {
          document_id
          document_name
        }
      }
    }
  }
`
